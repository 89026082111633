import React, { type ComponentProps, type PropsWithChildren } from 'react';

export type SvgT = PropsWithChildren<ComponentProps<'svg'>>;

export const Svg = ({ 'aria-label': ariaLabel, children, ...rest }: SvgT) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      width="100%"
      height="100%"
      aria-label={ariaLabel}
      {...rest}
    >
      {children}
    </svg>
  );
};
